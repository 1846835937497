import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "currency" }
const _hoisted_2 = {
  key: 0,
  class: "left-loading"
}
const _hoisted_3 = {
  key: 1,
  class: "currency-left"
}
const _hoisted_4 = { class: "part-1" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "doc" }
const _hoisted_7 = { class: "select-type" }
const _hoisted_8 = { class: "cash-out" }
const _hoisted_9 = {
  key: 1,
  class: "list"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "cash-in" }
const _hoisted_12 = {
  key: 1,
  class: "list"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "part-2" }
const _hoisted_15 = { class: "money" }
const _hoisted_16 = { class: "money-type" }
const _hoisted_17 = { class: "input" }
const _hoisted_18 = { class: "level" }
const _hoisted_19 = { class: "usable-money" }
const _hoisted_20 = { class: "tips" }
const _hoisted_21 = { class: "part-3" }
const _hoisted_22 = { class: "money" }
const _hoisted_23 = { class: "money-type" }
const _hoisted_24 = { class: "input" }
const _hoisted_25 = { class: "level" }
const _hoisted_26 = { class: "usable-money" }
const _hoisted_27 = {
  key: 0,
  class: "tips"
}
const _hoisted_28 = { class: "btn-wrap" }
const _hoisted_29 = { class: "record" }
const _hoisted_30 = {
  key: 2,
  class: "right-loading"
}
const _hoisted_31 = {
  key: 3,
  class: "right"
}
const _hoisted_32 = { class: "date" }
const _hoisted_33 = { class: "table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_VerifyPwd = _resolveComponent("VerifyPwd")!
  const _component_tips_dialog = _resolveComponent("tips-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.leftLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_el_skeleton, {
            rows: 5,
            animated: ""
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[16] || (_cache[16] = _createElementVNode("p", null, "请选择兑换方向", -1)),
                _createElementVNode("i", {
                  onClick: _cache[0] || (_cache[0] = () => _ctx.tipsDialog = true)
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                (_ctx.cashInfo.isShowCashOut === true)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: "bg",
                      onClick: _cache[1] || (_cache[1] = () => _ctx.cashInfo.isShowCashOut = false)
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: "type",
                  onClick: _cache[2] || (_cache[2] = () => _ctx.cashInfo.isShowCashOut = true)
                }, [
                  _createElementVNode("i", {
                    class: _normalizeClass(`${_ctx.cashOutItem.icon}-icon`)
                  }, null, 2),
                  _createElementVNode("div", null, _toDisplayString(_ctx.cashOutItem.name), 1),
                  _createElementVNode("div", null, _toDisplayString(_ctx.cashOutItem.value), 1),
                  _cache[17] || (_cache[17] = _createElementVNode("i", { class: "down-icon" }, null, -1))
                ]),
                (_ctx.cashInfo.isShowCashOut)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cashOutList, (item, idx) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "item",
                          key: idx,
                          onClick: ($event: any) => (_ctx.cashOutClick(item))
                        }, [
                          _createElementVNode("i", {
                            class: _normalizeClass(`${item.icon}-icon`)
                          }, null, 2),
                          _createElementVNode("div", null, _toDisplayString(item.name), 1),
                          _createElementVNode("div", null, _toDisplayString(item.value), 1)
                        ], 8, _hoisted_10))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", {
                class: "change-icon",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.exchangeTypeClick && _ctx.exchangeTypeClick(...args)))
              }),
              _createElementVNode("div", _hoisted_11, [
                (_ctx.cashInfo.isShowCashIn === true)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: "bg",
                      onClick: _cache[4] || (_cache[4] = () => _ctx.cashInfo.isShowCashIn = false)
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: "type",
                  onClick: _cache[5] || (_cache[5] = () => _ctx.cashInfo.isShowCashIn = true)
                }, [
                  _createElementVNode("i", {
                    class: _normalizeClass(`${_ctx.cashInItem.icon}-icon`)
                  }, null, 2),
                  _createElementVNode("div", null, _toDisplayString(_ctx.cashInItem.name), 1),
                  _createElementVNode("div", null, _toDisplayString(_ctx.cashInItem.value), 1),
                  _cache[18] || (_cache[18] = _createElementVNode("i", { class: "down-icon" }, null, -1))
                ]),
                (_ctx.cashInfo.isShowCashIn)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cashInList, (item, idx) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "item",
                          key: idx,
                          onClick: ($event: any) => (_ctx.cashInClick(item))
                        }, [
                          _createElementVNode("i", {
                            class: _normalizeClass(`${item.icon}-icon`)
                          }, null, 2),
                          _createElementVNode("div", null, _toDisplayString(item.name), 1),
                          _createElementVNode("div", null, _toDisplayString(item.value), 1)
                        ], 8, _hoisted_13))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _cache[20] || (_cache[20] = _createElementVNode("div", { class: "title" }, [
              _createElementVNode("i"),
              _createElementVNode("span", { class: "text" }, "兑出")
            ], -1)),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.cashOutItem.value), 1),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.cashInfo.outMoneyLevel), 1),
                _createVNode(_component_el_input, {
                  modelValue: _ctx.cashInfo.outValue,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.cashInfo.outValue) = $event)),
                  placeholder: _ctx.cashInfo.outPlaceholder,
                  onInput: _ctx.cashOutInputValueChange,
                  onBlur: _cache[7] || (_cache[7] = ($event: any) => (_ctx.cashBlur(_ctx.ChangeType.OUT)))
                }, null, 8, ["modelValue", "placeholder", "onInput"])
              ]),
              _createElementVNode("div", {
                class: "all",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.cashOutInputValueChange(_ctx.cashInfo.balanceValue)))
              }, "全部兑换")
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.cashInfo.errorTips), 1),
              _cache[19] || (_cache[19] = _createElementVNode("span", null, "可兑换金额", -1)),
              _createElementVNode("span", null, _toDisplayString(_ctx.cashInfo.balanceLabel), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _cache[22] || (_cache[22] = _createElementVNode("div", { class: "title" }, [
              _createElementVNode("i", { style: {"width":"88px"} }),
              _createElementVNode("span", { class: "text" }, "预计兑入")
            ], -1)),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.cashInItem.value), 1),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.cashInfo.inMoneyLevel), 1),
                _createVNode(_component_el_input, {
                  modelValue: _ctx.cashInfo.inValue,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.cashInfo.inValue) = $event)),
                  placeholder: "兑入结果",
                  onInput: _ctx.cashInInputValueChange,
                  onBlur: _cache[10] || (_cache[10] = ($event: any) => (_ctx.cashBlur(_ctx.ChangeType.IN)))
                }, null, 8, ["modelValue", "onInput"])
              ])
            ]),
            _createElementVNode("div", _hoisted_26, [
              (_ctx.cashInfo.outErrorTips)
                ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.cashInfo.outErrorTips), 1),
                    _createElementVNode("div", {
                      class: "all",
                      onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.clickAllOutValue && _ctx.clickAllOutValue(...args)))
                    }, _toDisplayString(_ctx.cashInfo.outErrorAllTips), 1)
                  ]))
                : _createCommentVNode("", true),
              _cache[21] || (_cache[21] = _createElementVNode("span", null, "参考汇率", -1)),
              _createElementVNode("span", null, _toDisplayString(_ctx.foreignList.rateLabel), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.btnDisabled ? 'btn-text' : 'btn-text disable']),
              onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.applyClick && _ctx.applyClick(...args)))
            }, _toDisplayString(_ctx.cashInfo.btnText), 3),
            _createElementVNode("div", _hoisted_29, [
              _createVNode(_component_router_link, { to: "/currency/record" }, {
                default: _withCtx(() => _cache[23] || (_cache[23] = [
                  _createTextVNode("查看兑换记录")
                ])),
                _: 1
              })
            ])
          ])
        ])),
    (_ctx.rightLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
          _createVNode(_component_el_skeleton, {
            rows: 5,
            animated: ""
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_31, [
          _createElementVNode("div", _hoisted_32, [
            _cache[24] || (_cache[24] = _createElementVNode("span", null, "选择日期：", -1)),
            _createVNode(_component_el_date_picker, {
              modelValue: _ctx.date,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.date) = $event)),
              type: "date",
              placeholder: "Pick a day",
              "disabled-date": _ctx.disabledDate,
              onChange: _ctx.dateChange
            }, null, 8, ["modelValue", "disabled-date", "onChange"])
          ]),
          _createElementVNode("div", _hoisted_33, [
            _createVNode(_component_el_table, {
              data: _ctx.tableData,
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, { label: "货币对" }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", null, _toDisplayString(scope.row.sourceCurrencyDisplay) + "/", 1),
                      _createElementVNode("span", null, _toDisplayString(scope.row.exchangeCurrencyDisplay), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("span", null, _toDisplayString(scope.row.sourceCurrency) + "/", 1),
                      _createElementVNode("span", null, _toDisplayString(scope.row.exchangeCurrency), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  prop: "bid",
                  label: "客户卖出价"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "ask",
                  label: "客户买入价"
                }),
                _createVNode(_component_el_table_column, { label: "涨跌幅" }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", {
                        class: _normalizeClass(scope.row.color)
                      }, _toDisplayString(scope.row.changePct), 3)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data"]),
            _cache[25] || (_cache[25] = _createElementVNode("p", { class: "tips" }, [
              _createElementVNode("span", null, "*"),
              _createTextVNode("数据仅供参考，具体价格以实际成交为准")
            ], -1))
          ])
        ])),
    _createVNode(_component_VerifyPwd, {
      verifyPwdDialog: _ctx.verifyData.verifyDialog,
      "onUpdate:verifyPwdDialog": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.verifyData.verifyDialog) = $event)),
      submitLoading: _ctx.pwdLoading || _ctx.submitLoading,
      onSubmitCallback: _ctx.submitCallback
    }, null, 8, ["verifyPwdDialog", "submitLoading", "onSubmitCallback"]),
    _createVNode(_component_tips_dialog, {
      tipsDialog: _ctx.tipsDialog,
      "onUpdate:tipsDialog": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.tipsDialog) = $event)),
      title: "本人知悉及确认",
      icon: "mark-icon"
    }, {
      content: _withCtx(() => _cache[26] || (_cache[26] = [
        _createElementVNode("div", { class: "content" }, [
          _createElementVNode("p", null, "1.汇率仅供参考，实际的兑换汇率会以交易当时汇率为准。"),
          _createElementVNode("p", null, " 2.「可兑换金额」仅供参考，此金额有可能包括本人仍未交收的款项。若将「可兑换金额」全数兑换，账户日后可能会出现结欠及需支付利息。 "),
          _createElementVNode("p", null, [
            _createElementVNode("span", null, " 3.若有任何疑问，请联系客户服务部("),
            _createElementVNode("span", { class: "email" }, "cs@igoldhorse.com"),
            _createElementVNode("span", null, ")查询。 ")
          ])
        ], -1)
      ])),
      _: 1
    }, 8, ["tipsDialog"])
  ]))
}