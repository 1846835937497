import { useMutation } from '@vue/apollo-composable'
import { Ref, reactive } from 'vue'
import * as currancyApi from '@/graphql/currency'
import { CashInfo } from './useCurrencyData'
import { Currency } from './useChangeType'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { formatNumberToFinance } from '@/utils/num_format'
import storage, { StorageType } from '@/utils/storage'
import { DesensitizationType } from '@/utils/desensitization'
import * as paramsWrap from '@/graphql/params-wrap'
import useDisabled from '@/hooks/useDisabled'

export default function usePasswordDialog (cashInfo: CashInfo, foreignList: any, cashInItem: Ref<Currency>, cashOutItem: Ref<Currency>, btnDisabled: Ref<boolean>) {
  const router = useRouter()
  const verifyData = reactive({
    verifyDialog: false,
    password: '',
    errorTips: ''
  })

  const { isDdisableStatus } = useDisabled()

  const { loading: pwdLoading, mutate: checkPwdFcun, onDone: checkPwdDone } = useMutation(currancyApi.verifyTradePasswd)
  const { loading: submitLoading, mutate: submit, onDone: submitDone } = useMutation(currancyApi.foreignExchangeApplicationCommit)

  const applyClick = () => {
    if (!isDdisableStatus.isSwap) {
      ElMessage({
        message: '暂不支持',
        type: 'warning'
      })
      return
    }
    if (!btnDisabled.value) {
      return
    }
    verifyData.verifyDialog = true
  }

  const submitCallback = (password: string) => {
    if (pwdLoading.value || submitLoading.value) return
    const clientId = storage.rcGetItem(StorageType.local, 'userInfo')

    const params = {
      param: {
        clientId,
        tradePassword: paramsWrap.wrapSign(password, {
          type: DesensitizationType.full
        }),
        authType: 1
      }
    }
    checkPwdFcun(params)
  }

  checkPwdDone((res) => {
    const { verifyTradePasswd } = res.data
    if (verifyTradePasswd.ok) {
      const clientId = storage.rcGetItem(StorageType.local, 'userInfo')
      const params = {
        param: {
          tradeAccount: clientId,
          fundAccount: clientId,
          outCurrencyType: cashOutItem.value.value,
          inCurrencyType: cashInItem.value.value,
          exchangeOutMoney: cashInfo.outValue.replace(/,/g, ''),
          exchangeInMoney: cashInfo.inValue.replace(/,/g, ''),
          twofaAuthToken: verifyTradePasswd.data
        }
      }
      submit(params)
    } else {
      ElMessage({
        message: verifyTradePasswd?.reason?.desc,
        type: 'error',
        customClass: 'custom-error'
      })
    }
  })

  submitDone((res) => {
    const { foreignExchangeApplicationCommit } = res.data
    if (foreignExchangeApplicationCommit.ok) {
      verifyData.verifyDialog = false
      ElMessage.success('提交成功')
      router.push({ name: 'Record' })
    } else {
      verifyData.password = ''
      if (foreignExchangeApplicationCommit?.reason?.err === 9) {
        cashInfo.btnTimeStatus = false
        cashInfo.codeNineStatus = true
        verifyData.verifyDialog = false
        cashInfo.inAllValue = formatNumberToFinance(foreignExchangeApplicationCommit?.reason?.desc)
        if (Number(foreignList.foreignExchangeData.isPartSwap)) {
          cashInfo.outErrorTips = `当前最多可兑入金额为${cashInfo.inAllValue},`
          cashInfo.outErrorAllTips = '全部兑入'
        } else {
          cashInfo.outErrorTips = `当前最多可兑入金额为${cashInfo.inAllValue}。`
          cashInfo.outErrorAllTips = ''
        }
      } else {
        ElMessage.error(foreignExchangeApplicationCommit?.reason?.desc)
      }
    }
  })
  return {
    verifyData,
    applyClick,
    submitCallback,
    pwdLoading,
    submitLoading
  }
}
