
import { defineComponent, ref } from 'vue'
import useCurrencyData, { ChangeType } from './hooks/useCurrencyData'
import usePasswordDialog from './hooks/usePasswordDialog'
import useGetForeign from './hooks/useGetForeign'
import tipsDialog from '@/components/tipsDialog/index.vue'

export default defineComponent({
  components: {
    tipsDialog
  },
  setup () {
    const tipsDialog = ref(false)
    const outMoney = ref()
    const {
      leftLoading,
      cashInfo,
      cashInClick,
      cashOutClick,
      exchangeTypeClick,
      cashInItem,
      cashOutItem,
      accountList,
      accountActive,
      cashOutList,
      cashInList,
      foreignList,
      btnDisabled,
      cashOutInputValueChange,
      cashInInputValueChange,
      clickAllOutValue,
      cashBlur
    } = useCurrencyData()

    const { verifyData, applyClick, submitCallback, pwdLoading, submitLoading } = usePasswordDialog(cashInfo, foreignList, cashInItem, cashOutItem, btnDisabled)

    const { rightLoading, date, tableData, disabledDate, dateChange } = useGetForeign()

    return {
      tipsDialog,
      leftLoading,
      rightLoading,
      cashInfo,
      foreignList,
      outMoney,
      accountActive,
      accountList,
      cashOutList,
      cashInList,
      cashInClick,
      cashOutClick,
      exchangeTypeClick,
      cashInItem,
      cashOutItem,
      btnDisabled,
      applyClick,
      submitCallback,
      cashOutInputValueChange,
      cashInInputValueChange,
      clickAllOutValue,
      date,
      tableData,
      disabledDate,
      dateChange,
      pwdLoading,
      submitLoading,
      cashBlur,
      ChangeType,
      verifyData
    }
  }
})
