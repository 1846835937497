import gql from 'graphql-tag'

export const currencyData = gql`
  query currencyData(
    $filter: FilterFundAccBalanceListInput!
    $filterDict: FilterFindDictSystemInput
  ) {
    fundAccBalanceList: FundAccBalanceList(filter: $filter) {
      ok
      reason {
        err
        desc
      }
      data {
        fundAccount
        assetProp
        moneyType
        currentBalance
        enableBalance
        fetchBalance
        frozenBalance
        marginCall
        unsettBalance
      }
    }
    findDictSystem: findDictSystem(filter: $filterDict) {
      ok
      reason {
        err
        desc
      }
      data
    }
    foreignExchangeSetup: foreignExchangeSetup {
      ok
      data {
        isPartSwap
        isHkdStartAmount
        hkdStartAmount
        isUsdStartAmount
        usdStartAmount
        hkdInLargeAmount
        hkdOutLargeAmount
        usdInLargeAmount
        usdOutLargeAmount
        isCnhStartAmount
        cnhStartAmount
        cnhInCustomerLimit
        cnhOutCustomerLimit
        cnhInLargeAmount
        cnhOutLargeAmount
        isEurStartAmount
        eurStartAmount
        eurInCustomerLimit
        eurOutCustomerLimit
        eurInLargeAmount
        eurOutLargeAmount
        isGbpStartAmount
        gbpStartAmount
        gbpInCustomerLimit
        gbpOutCustomerLimit
        gbpInLargeAmount
        gbpOutLargeAmount
        businessTimeType
        businessTimePeriod {
          beginTime
          endTime
        }
        residualLimitList {
          currencyType
          residualLimit
        }
        foreignExchangeRateList {
          outCurrencyType
          inCurrencyType
          ratePrice
          algorithmType
        }
      }
      reason {
        err
        desc
      }
    }
  }
`

// 查询外汇汇率
export const foreignExchangeRatesByDate = gql`
  query foreignExchangeRatesByDate($filter: FilterForeignExchangeRateByDate) {
    foreignExchangeRatesByDate(filter: $filter) {
      exchangeDate
      foreignExchangeRateInfoList {
        sourceCurrency
        exchangeCurrency
        sourceCurrencyDisplay
        exchangeCurrencyDisplay
        bid
        ask
        changePct
        changePctMark
      }
    }
  }
`

// 验证交易密码
export const verifyTradePasswd = gql`
  mutation verifyTradePasswd($param: VerifyTradePasswdInput!) {
    verifyTradePasswd(param: $param) {
      ok
      data
      reason {
        err
        desc
      }
    }
  }
`

// 货币兑换接口
export const foreignExchangeApplicationCommit = gql`
  mutation foreignExchangeApplicationCommit(
    $param: ParamForeignExchangeApplicationInput!
  ) {
    foreignExchangeApplicationCommit(param: $param) {
      ok
      data
      reason {
        err
        desc
      }
    }
  }
`

// 查询兑换记录
export const foreignExchangeRecords = gql`
  query foreignExchangeRecords(
    $filter: FilterForeignExchangeRecordsInput!
    $params: FindDictInput
  ) {
    foreignExchangeRecords(filter: $filter) {
      ok
      data {
        records {
          exchangeOri
          outCurrencyType
          inCurrencyType
          exchangeOutMoney
          exchangeInMoney
          applicationStatus
          reasonDesc
          createTime
        }
        total
        size
        current
        optimizeCountSql
        hitCount
        countId
        maxLimit
        searchCount
        pages
      }
      reason {
        err
        desc
      }
    }
    findDictOpenAccount(param: $params) {
      data
      ok
      reason {
        err
        desc
      }
    }
  }
`
