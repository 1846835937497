import storage, { StorageType } from '@/utils/storage'
import { reactive } from 'vue'

export default function useDisabled () {
  const isDdisableStatus = reactive({
    isTrading: true,
    isSwap: true
  })
  const clientId = storage.rcGetItem(StorageType.local, 'userInfo')
  const userData = storage.rcGetItem(StorageType.local, 'userData') || []
  const tradeInfo = userData?.clientList.filter((v) => v.clientId === clientId)[0]
  // 投资移民账户
  if (tradeInfo.clientType === 9 && tradeInfo.fundAccountType === 3) {
    isDdisableStatus.isTrading = false
  } else if (tradeInfo.clientType === 10) { // 移民子账户
    isDdisableStatus.isSwap = false
    if (tradeInfo.hostingStatus !== 0) { // 被委托
      isDdisableStatus.isTrading = false
    }
  } else {
    if (tradeInfo.hostingStatus !== 0) { // 被委托
      isDdisableStatus.isTrading = false
    }
  }
  return {
    isDdisableStatus
  }
}
