import { useQuery } from '@vue/apollo-composable'
import * as currancyApi from '@/graphql/currency'
import { Ref, computed, reactive, ref, watch } from 'vue'
import { Currency } from './useChangeType'
import _ from 'lodash'
import Decimal from 'decimal.js'
import { formatFinanceToNumber, formatNumberToFinance, getNumberLevel } from '@/utils/num_format'
import dayjs from 'dayjs'
import webSocket from '@/mqtt'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { useStore } from 'vuex'
import storage, { StorageType } from '@/utils/storage'
import useDisabled from '@/hooks/useDisabled'

dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

type AccountList = {
  label: string
  value: string
}

type RateList = {
  algorithmType: string
  inCurrencyType: string
  outCurrencyType: string
  ratePrice: string
}

export type CashInfo = {
  isShowCashIn: boolean
  isShowCashOut: boolean
  inValue: string
  outValue: string
  inAllValue: string
  balanceLabel: string
  balanceValue: string
  balanceList: any
  outPlaceholderValue: string
  outPlaceholder: string
  outMoneyLevel: string
  inMoneyLevel: string
  errorTips: string
  btnText: string
  btnTimeStatus: boolean
  outErrorTips: string
  outErrorAllTips: string
  codeNineStatus: boolean
}

enum BussinessTimeType {
  ALL = '1',
  BTW = '2',
  STOP = '3'
}

export enum ChangeType {
  IN,
  OUT
}

export default function useCurrencyData () {
  const store = useStore()
  const params = {
    filter: {
      fundAccount: storage.rcGetItem(StorageType.local, 'userInfo')
    },
    filterDict: {
      markList: ['FOREIGN_SEC_MONEY_TYPE']
    }
  }
  // 兑入列表
  const cashInList: Ref<Currency[]> = ref([])
  // 兑出列表
  const cashOutList: Ref<Currency[]> = ref([])
  // 资金账号列表
  const accountList: Ref<AccountList[]> = ref([])
  // 选中资金账号
  const accountActive: Ref<string> = ref('')

  // 兑入选中
  const cashInItem: Ref<Currency> = ref({
    icon: 'USD',
    name: '美元',
    value: 'USD'
  })
  // 兑出选中
  const cashOutItem: Ref<Currency> = ref({
    icon: 'HKD',
    name: '港元',
    value: 'HKD'
  })

  const cashInfo: CashInfo = reactive({
    isShowCashIn: false,
    isShowCashOut: false,
    inValue: '',
    outValue: '',
    inAllValue: '',
    balanceLabel: '',
    balanceValue: '',
    balanceList: [],
    outPlaceholderValue: '',
    outPlaceholder: '请输入起兑金额',
    outMoneyLevel: '',
    inMoneyLevel: '',
    errorTips: '',
    outErrorTips: '',
    outErrorAllTips: '',
    btnText: '申请兑换',
    btnTimeStatus: false,
    codeNineStatus: false
  })

  // 汇率相关
  const foreignList = reactive({
    foreignExchangeData: {
      foreignExchangeRateList: [],
      businessTimeType: '',
      businessTimePeriod: [],
      residualLimitList: [],
      isPartSwap: ''
    },
    rateValue: {},
    rateLabel: ''
  })

  const { isDdisableStatus } = useDisabled()

  const createPlaceHolder = () => {
    // 起对金额
    const isStartAmount = `is${_.capitalize(cashOutItem.value.value)}StartAmount`
    if (foreignList.foreignExchangeData[isStartAmount] === '1') {
      cashInfo.outPlaceholderValue = `${foreignList.foreignExchangeData[`${cashOutItem.value.value.toLowerCase()}StartAmount`]}`
      cashInfo.outPlaceholder = `${cashOutItem.value.name}起兑金额为${foreignList.foreignExchangeData[`${cashOutItem.value.value.toLowerCase()}StartAmount`]}`
    }
    // 计算参考汇率
    const rateList: RateList[] = foreignList.foreignExchangeData.foreignExchangeRateList
      .filter((val: { outCurrencyType: string, inCurrencyType: string }) => (val.outCurrencyType === cashOutItem.value.value && val.inCurrencyType === cashInItem.value.value))
    if (Number(rateList[0].algorithmType)) {
      foreignList.rateLabel = `1${cashOutItem.value.name}=${rateList[0]?.ratePrice}${cashInItem.value.name}`
    } else {
      foreignList.rateLabel = `1${cashOutItem.value.name}=1/${rateList[0]?.ratePrice}${cashInItem.value.name}`
    }
    foreignList.rateValue = rateList[0]
  }

  const createBalance = () => {
    const list: {fetchBalance: string}[] = cashInfo.balanceList.filter((v: { moneyType: string }) => (v.moneyType === cashOutItem.value.value))
      if (list.length) {
        cashInfo.balanceLabel = `${formatNumberToFinance(new Decimal(list[0].fetchBalance).toFixed(2, Decimal.ROUND_DOWN))}${cashOutItem.value.name}`
        cashInfo.balanceValue = list[0].fetchBalance
      } else {
        cashInfo.balanceLabel = `${0}${cashOutItem.value.name}`
      }
  }

  // button文案
  const formatBtnInfo = () => {
    const { businessTimeType, businessTimePeriod } = foreignList.foreignExchangeData
    switch (businessTimeType) {
      case BussinessTimeType.BTW:
        for (let index = 0; index < businessTimePeriod.length; index++) {
          const beginTime = dayjs((businessTimePeriod[index] as { beginTime: string }).beginTime, 'HH:mm:ss')
          const endTime = dayjs((businessTimePeriod[index] as { endTime: string }).endTime, 'HH:mm:ss')
          const time = dayjs(new Date()).isBetween(beginTime, dayjs(endTime))
          if (time) {
            const large = foreignList.foreignExchangeData[`${cashOutItem.value.value.toLowerCase()}OutLargeAmount`]
            if (Number(formatFinanceToNumber(cashInfo.outValue)) > Number(large)) {
              cashInfo.btnText = '大额兑换，请联系客服'
              cashInfo.btnTimeStatus = false
            } else {
              cashInfo.btnText = '申请兑换'
              cashInfo.btnTimeStatus = true
            }
            break
          } else {
            cashInfo.btnTimeStatus = false
            if ((businessTimePeriod[index + 1] as { beginTime: string })?.beginTime) {
              const isBefore = dayjs().isBefore(dayjs((businessTimePeriod[index + 1] as { beginTime: string })?.beginTime, 'HH:mm:ss'))
              if (isBefore) {
                cashInfo.btnText = `当前时段暂不支持下单，预计${dayjs((businessTimePeriod[index + 1] as { beginTime: string })?.beginTime, 'HH:mm:ss').format('HH:mm:ss')}后恢复`
                break
              }
            } else {
              cashInfo.btnText = `当前时段暂不支持下单，预计${dayjs((businessTimePeriod[0] as { beginTime: string })?.beginTime, 'HH:mm:ss').format('HH:mm:ss')}后恢复`
            }
          }
        }
        break
      case BussinessTimeType.STOP:
          cashInfo.btnTimeStatus = false
          cashInfo.btnText = '暂停营业'
        break
      case BussinessTimeType.ALL:
          // cashInfo.btnTimeStatus = true
          const large = foreignList.foreignExchangeData[`${cashOutItem.value.value.toLowerCase()}OutLargeAmount`]
          if (Number(formatFinanceToNumber(cashInfo.outValue)) > Number(large)) {
            cashInfo.btnText = '大额兑换，请联系客服'
            cashInfo.btnTimeStatus = false
          } else {
            cashInfo.btnText = '申请兑换'
            cashInfo.btnTimeStatus = true
          }
        break
      default:
        break
    }
  }

  // 限制最大金额
  const formatLimit = () => {
    const max: { residualLimit: string }[] = foreignList.foreignExchangeData.residualLimitList.filter((val: { currencyType: string }) => (val.currencyType === cashInItem.value.value))
    if (max.length) {
      if (Number(formatFinanceToNumber(cashInfo.inValue)) > Number(max[0].residualLimit)) {
        cashInfo.btnTimeStatus = false
        cashInfo.inAllValue = formatNumberToFinance(max[0].residualLimit)
        if (Number(foreignList.foreignExchangeData.isPartSwap)) {
          cashInfo.outErrorTips = `当前最多可兑入金额为${max[0].residualLimit},`
          cashInfo.outErrorAllTips = '全部兑入'
        } else {
          cashInfo.outErrorTips = `当前最多可兑入金额为${max[0].residualLimit}。`
        }
      } else {
        cashInfo.outErrorTips = ''
        cashInfo.outErrorAllTips = ''
      }
    }
  }

  watch(() => store.getters.currencyMessage, (val: string) => {
    const { data } = JSON.parse(val)
    foreignList.foreignExchangeData = data?.message
    createPlaceHolder()
    formatBtnInfo()
    formatLimit()
  }, { deep: true })

  // 查询基础数据
  const { loading: leftLoading, onResult } = useQuery(currancyApi.currencyData, params)
  // 基础数据回调
  onResult((res) => {
    const { findDictSystem, foreignExchangeSetup, fundAccBalanceList } = res?.data
    // 查询数据字典
    if (findDictSystem.ok) {
      const { FOREIGN_SEC_MONEY_TYPE } = findDictSystem.data
      const moneyType = FOREIGN_SEC_MONEY_TYPE.map((val: Currency) => {
        return {
          ...val,
          icon: val.value
        }
      })
      cashInItem.value = moneyType[1]
      cashOutItem.value = moneyType[0]
      cashInList.value = moneyType
      cashOutList.value = moneyType
    }

    if (fundAccBalanceList.ok) {
      const { data } = fundAccBalanceList
      cashInfo.balanceList = data
      createBalance()
    }

    // 查询汇率
    if (foreignExchangeSetup.ok) {
      const { data } = foreignExchangeSetup
      foreignList.foreignExchangeData = data
      webSocket.subscribe(['push/public/transMsg'])
      createPlaceHolder()
      formatBtnInfo()
    }
  })

  const cashOutInputValueChange = (val: string) => {
    // 正则判断只能输入数字、小数点、并且保留两位小数
    cashInfo.outValue = val.replace(/\s?|(,*)/g, '')
      .replace(/[^0-9.]/g, '')
      .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    const money = formatFinanceToNumber(cashInfo.outValue)
    cashInfo.outMoneyLevel = getNumberLevel(Number(money))
    if (money) {
      // 兑入兑出输入框联动
      if (Number((foreignList.rateValue as { algorithmType: string }).algorithmType)) {
        cashInfo.inValue = formatNumberToFinance(new Decimal(money)
          .mul((foreignList.rateValue as { ratePrice: string }).ratePrice)
          .toFixed(2, Decimal.ROUND_DOWN))
      } else {
        cashInfo.inValue = formatNumberToFinance(new Decimal(money)
          .div((foreignList.rateValue as { ratePrice: string }).ratePrice)
          .toFixed(2, Decimal.ROUND_DOWN))
      }
      const inMoney = formatFinanceToNumber(cashInfo.inValue)
      cashInfo.inMoneyLevel = getNumberLevel(Number(inMoney))
    // 当输入大于可兑换金额时提示
      if (Number(money) > Number(cashInfo.balanceValue)) {
        cashInfo.errorTips = '大于可用余额'
        return
      } else {
        cashInfo.errorTips = ''
      }
      // 当输入小于起始兑换金额时
      if (Number(money) < Number(cashInfo.outPlaceholderValue)) {
        cashInfo.errorTips = cashInfo.outPlaceholder
      } else {
        cashInfo.errorTips = ''
      }
    } else {
      cashInfo.inValue = ''
      cashInfo.errorTips = ''
      cashInfo.inMoneyLevel = ''
    }
    formatBtnInfo()
    formatLimit()
  }

  const cashInInputValueChange = (val: string) => {
    // 正则判断只能输入数字、小数点、并且保留两位小数
    cashInfo.inValue = val.replace(/\s?|(,*)/g, '')
      .replace(/[^0-9.]/g, '')
      .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    const money = formatFinanceToNumber(cashInfo.inValue)
    cashInfo.inMoneyLevel = getNumberLevel(Number(money))
    if (money) {
      // 兑入兑出输入框联动
      if (Number((foreignList.rateValue as { algorithmType: string }).algorithmType)) {
        cashInfo.outValue = formatNumberToFinance(new Decimal(money)
          .div((foreignList.rateValue as { ratePrice: string }).ratePrice)
          .toFixed(2, Decimal.ROUND_UP))
      } else {
        cashInfo.outValue = formatNumberToFinance(new Decimal(money)
          .mul((foreignList.rateValue as { ratePrice: string }).ratePrice)
          .toFixed(2, Decimal.ROUND_UP))
      }
      const outValue = formatFinanceToNumber(cashInfo.outValue)
      cashInfo.outMoneyLevel = getNumberLevel(Number(outValue))
      // 当输入大于可兑换金额时提示
      if (Number(outValue) > Number(cashInfo.balanceValue)) {
        cashInfo.errorTips = '大于可用余额'
        return
      } else {
        cashInfo.errorTips = ''
      }
      // 当输入小于起始兑换金额时
      if (Number(outValue) < Number(cashInfo.outPlaceholderValue)) {
        cashInfo.errorTips = cashInfo.outPlaceholder
      } else {
        cashInfo.errorTips = ''
      }
    } else {
      cashInfo.outValue = ''
      cashInfo.errorTips = ''
      cashInfo.outMoneyLevel = ''
    }
    formatBtnInfo()
    formatLimit()
  }

  // 失焦触发时间
  const cashBlur = (val: ChangeType) => {
    if (val === ChangeType.IN) {
      const num = new Decimal(formatFinanceToNumber(cashInfo.inValue)).toFixed(2, Decimal.ROUND_DOWN)
      cashInfo.inValue = formatNumberToFinance(num)
    } else if (val === ChangeType.OUT) {
      const num = new Decimal(formatFinanceToNumber(cashInfo.outValue)).toFixed(2, Decimal.ROUND_UP)
      cashInfo.outValue = formatNumberToFinance(num)
    }
  }

  const clickAllOutValue = () => {
    cashInInputValueChange(cashInfo.inAllValue)
    cashInfo.outErrorTips = ''
    cashInfo.outErrorAllTips = ''
  }

  const cashInClick = (item: Currency) => {
    cashInfo.isShowCashIn = false
    if (item.value === cashOutItem.value.value) {
      const cashOutActiveIdx = cashInList.value.findIndex((val: { value: string }) => val.value === cashOutItem.value.value)
      if (cashOutActiveIdx) {
        cashOutItem.value = cashInList.value[0]
      } else {
        cashOutItem.value = cashInList.value[1]
      }
    }
    cashInItem.value = item
    createPlaceHolder()
    createBalance()
    cashOutInputValueChange(String(formatFinanceToNumber(cashInfo.outValue)))
  }

  const cashOutClick = (item: Currency) => {
    cashInfo.isShowCashOut = false
    if (item.value === cashInItem.value.value) {
      const cashOutActiveIdx = cashOutList.value.findIndex((val: { value: string }) => val.value === cashInItem.value.value)
      if (cashOutActiveIdx) {
        cashInItem.value = cashOutList.value[0]
      } else {
        cashInItem.value = cashOutList.value[1]
      }
    }
    cashOutItem.value = item
    createPlaceHolder()
    createBalance()
    cashOutInputValueChange(String(formatFinanceToNumber(cashInfo.outValue)))
    formatBtnInfo()
    formatLimit()
  }

  // 兑换货币互换
  const exchangeTypeClick = () => {
    const cashIn = cashInItem.value
    const cashOut = cashOutItem.value
    cashInItem.value = cashOut
    cashOutItem.value = cashIn
    createPlaceHolder()
    createBalance()
    cashOutInputValueChange(String(formatFinanceToNumber(cashInfo.outValue)))
    formatBtnInfo()
    formatLimit()
  }

  const btnDisabled = computed(() => {
    return Boolean(!cashInfo.errorTips && cashInfo.outValue && cashInfo.inValue && cashInfo.btnTimeStatus && isDdisableStatus.isSwap)
  })

  return {
    leftLoading,
    cashInList,
    cashInfo,
    cashOutList,
    accountList,
    accountActive,
    cashInClick,
    cashOutClick,
    exchangeTypeClick,
    cashInItem,
    cashOutItem,
    foreignList,
    btnDisabled,
    cashOutInputValueChange,
    cashInInputValueChange,
    clickAllOutValue,
    cashBlur
  }
}
